@use "../config" as *;
@forward "./common-standings";
.waf-component {
    &.waf-standings {
        @extend %px-0;
        @extend %pb-0;
        @extend %mb-6;
    }
    &.waf-standings-static {
        @extend %p-0;
    }
}
.waf-standings {
    @extend %px-1;
    @extend %pt-3;
    .head-tab,
    .dropdown-wrap {
        @extend %d-none;
    }
    .layout-wrapper {
        @extend %relative;
    }
    .waf-footer {
        @extend %d-none;
    }
}
.waf-standings-static {
    box-shadow: 0 0.4rem 0.4rem 0 var(--hsl-black);
    @extend %quarter-radius;
    @extend %white-bg;
    .lower-alpha {
        list-style-type: lower-alpha;
    }
    li {
        @extend %mb-2;
        &:last-of-type {
            @extend %mb-0;
        }
        &::marker {
            @extend %font-14-pb;
        }
    }
    .text {
        @extend %font-14-pr;
        @extend %secondary-dark;
    }
    .waf-head {
        .title {
            @extend %font-15-pb;
        }
    }
    .waf-body {
        @extend %py-5;
        @extend %px-3;
    }
    .title {
        text-transform: none;
        @extend %p-3;
        @extend %secondary-light-bg-1;
        @extend %mb-0;
    }
}
.standings-table {
    @extend %px-2;
    @extend %mt-3;
    .table {
        &-head {
            border-radius: var(--quarter-radius) var(--quarter-radius) 0 0;
        }
        &-data {
            @extend %p-0;
            &-wrap {
                flex-shrink: 0;
                @extend %flex-n-c;
            }
            &.matches-goal-for,
            &.matches-goal-against,
            &.form-guide {
                @extend %d-none;
            }
            &.matches-team {
                justify-content: flex-start;
                flex-shrink: 0;
                width: 25%;
                .table-data-wrap {
                    @extend %gap-1;
                    @extend %flex-n-c;
                }
                .text {
                    @extend %capitalize;
                }
            }
            &.form-guide {
                width: 16%;
                flex-shrink: 0;
            }
            &.form-more {
                .text,
                .btn-text {
                    @extend %font-0;
                }
                .btn-text {
                    &::after {
                        transform-origin: center;
                        transform: rotate(0deg);
                        display: inline-flex;
                        @extend %transition;
                        @include icon(chevron-down, 10);
                    }
                }
            }
            &:first-of-type {
                @extend %pl-2;
            }
            &:last-of-type {
                @extend %pr-2;
            }
        }
        &-body {
            @extend %white-bg;
        }
        &-row {
            @extend %relative;
            &.qualifier {
                .position {
                    @extend %d-none;
                }
                .position-indicator {
                    width: 1.8rem;
                    height: 1.8rem;
                    aspect-ratio: 1/1;
                    @extend %accent-bg;
                    @extend %quarter-radius;
                    @extend %flex-c-c;
                }
            }
            &.active {
                .waf-accordion-head {
                    @extend %accent-bg;
                }
                .qualifier .position-indicator {
                    @extend %primary-bg;
                    @extend %white;
                }
                .table-data {
                    &.form-more {
                        .btn-text {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
            &::after {
                content: '';
                height: 0.1rem;
                width: calc(100% - 2rem);
                @include position(null, 1rem, 0);
                @extend %secondary-light-bg-2;
            }
            &:last-of-type::after {
                content: unset;
            }
        }
    }
    .position {
        @extend %d-block;
    }
    .position-indicator {
        @extend %uppercase;
        @extend %d-none;
    }
    .team {
        &-image {
            width: 2.5rem;
            height: 2.5rem;
            aspect-ratio: 1/1;
        }
        &-anchor:hover {
            text-decoration: underline;
        }
        &-name {
            font-weight: 700;
            @extend %uppercase;
            .full-name {
                @extend %d-none;
            }
            .short-name {
                @extend %d-block;
            }
        }
        &-score-wrap {
            @extend %flex-n-c;
            @extend %quarter-radius;
            @extend %px-2;
            @extend %py-1;
            @extend %gap-5;
            .score {
                @extend %font-16-pb;
                @extend %white-7;
            }
            .time-text {
                @extend %font-12-pm;
                @extend %white;
                @extend %gap-1;
                @extend %flex-n-c;
                &::before {
                    @include icon(clock, 12);
                }
            }
            .won {
                .score {
                    @extend %accent;
                }
            }
        }
        &-info-wrap {
            flex-wrap: wrap;
            @extend %flex-c-c;
            @extend %gap-1;
        }
    }
    .waf-accordion-panel {
        @extend %w-100;
        @extend %mid-grey-bg-2;
        @extend %p-4;
    }
    .form-guide {
        @include border(1, black, 1, bottom);
        @extend %flex-n-c;
        @extend %pb-4;
        @extend %mb-3;
        &-label {
            width: 34%;
            @extend %pr-3;
            @extend %mr-3;
            @extend %relative;
            &::after {
                content: "";
                width: 0.2rem;
                height: 100%;
                @extend %black-bg-1;
                @extend %position-t-r;
            }
            .text {
                @extend %font-12-pm;
                @extend %secondary-light;
                @extend %capitalize;
            }
        }
        &-listing {
            flex-grow: 1;
            list-style: none;
            flex-wrap: wrap;
            @extend %gap-2;
            @extend %p-0;
            @extend %flex-n-c;
        }
        &-item {
            width: 2.8rem;
            height: 2.8rem;
            aspect-ratio: 1/1;
            @extend %circle-radius;
            @extend %dark-grey-bg;
            @extend %flex-c-c;
            .text {
                font-weight: 700;
            }
            &.win {
                @extend %success-bg;
            }
            &.lost {
                @extend %error-bg;
            }
        }
    }
    .match {
        &-item {
            @extend %relative;
            @extend %white-bg;
            @extend %quarter-radius;
            @extend %p-2;
            @extend %mb-4;
            @extend %mt-8;
            &.match-item-recent {
                .match-time {
                    @extend %d-none;
                }
                .team {
                    &-score-wrap {
                        background: linear-gradient(104.94deg, var(--secondary-dark) 34.65%, var(--primary) 122.07%);
                        @extend %relative;
                        &::after {
                            content: "";
                            width: 0.1rem;
                            height: calc(100% - 2rem);
                            @extend %white-bg-5;
                            @extend %position-x-center;
                        }
                    }
                    &-extra {
                        @extend %d-none;
                    }
                }
            }
            &.match-item-upcoming {
                .team-a-score-wrap,
                .team-b-score-wrap {
                    @extend %d-none;
                }
                .team {
                    &-score-wrap {
                        @extend %secondary-bg;
                    }
                }
            }
            &:last-of-type {
                @extend %mb-0;
            }
            .btn-wrap {
                @extend %position-t-l;
                @extend %w-100;
                @extend %h-100;
                .btn-more {
                    @extend %d-block;
                    @extend %w-100;
                    @extend %h-100;
                }
                .btn-text {
                    @extend %font-0;
                }
            }
            .team {
                &.team-b {
                    .team-info-wrap {
                        flex-direction: row-reverse;
                    }
                }
                &-name {
                    .name {
                        @extend %font-14;
                    }
                }
                &-image {
                    width: 3.5rem;
                    height: 3.5rem;
                    aspect-ratio: 1/1;
                }
            }
        }
        &-meta {
            @extend %capitalize;
        }
        &-status {
            @include position(-3rem, null, null, 0);
            @extend %font-12-pb;
        }
        &-date {
            @include border(1, black, 1, bottom);
            @extend %text-center;
            @extend %font-10-pb;
            @extend %pb-2;
            @extend %mb-2;
        }
        &-details {
            @extend %flex-c-c;
            @extend %gap-2;
        }
    }
}
@include mq(col-lg) {
    .standings-table {
        .table {
            &-data {
                &.form-guide {
                    border-bottom: 0;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    @include flex-config(flex, row, center, center);
                }
                &.form-more {
                    .text {
                        font-size: inherit;
                    }
                }
                &-wrap {
                    gap: var(--space-4);
                }
                &.matches-position {
                    justify-content: flex-start;
                    .text {
                        padding-left: var(--space-3);
                    }
                }
                &.matches-goal-for,
                &.matches-goal-against {
                    display: flex;
                }
            }
            &-row {
                &.qualifier {
                    .position {
                        padding-right: 0;
                        display: block;
                    }
                }
            }
            &-head {
                .text {
                    text-transform: capitalize;
                }
            }
        }
        .position {
            padding-left: var(--space-3);
            padding-right: var(--space-7);
        }
        .form-guide {
            display: none;
            &-item {
                position: relative;
                cursor: pointer;
                .match {
                    &-item {
                        display: none;
                        width: max-content;
                        margin-top: 0;
                        margin-bottom: 0;
                        box-shadow: 0 0 0.4rem 0.4rem hsl(var(--hsl-black) / 0.1);
                        isolation: isolate;
                        @include position(null, 0, calc(100% + 1rem));
                        &::after {
                            content: "";
                            display: inline-block;
                            width: 1.5rem;
                            height: 1.5rem;
                            background-color: hsl(var(--hsl-white) / 1);
                            z-index: -1;
                            transform: rotate(45deg);
                            @include position(null, 0.7rem, -0.7rem);
                        }
                    }
                }
                &:hover {
                    .match {
                        &-item {
                            display: block;
                        }
                    }
                }
            }
            &-listing {
                justify-content: center;
                flex-wrap: nowrap;
            }
        }
        .match {
            &-list-wrap {
                gap: var(--space-6);
                flex-wrap: wrap;
                @include flex-config(flex, row, center, center);
            }
            &-item {
                margin-bottom: 0;
            }
        }
        .waf-accordion-head {
            .team-name {
                .full-name {
                    display: block;
                    text-transform: capitalize;
                }
                .short-name {
                    display: none;
                }
            }
            .match-item {
                .team-name {
                    .full-name {
                        display: none;
                    }
                    .short-name {
                        display: block;
                    }
                }
            }
        }
    }
    .waf-component {
        &.waf-standings-static {
            padding: var(--space-10);
        }
    }
    .waf-standings-static {
        padding: var(--space-2);
        li {
            margin-bottom: var(--space-6);
            li {
                margin-bottom: var(--space-4);
            }
        }
    }
}