@use "../config" as *;

.standings-table{
    .table{
        &-head{
            .table-data{
                height: 4rem;
            }
        }
        &-data {
            @extend %flex;
            @extend %px-1;
        }
        &-body {
            > .table-row{
                flex-direction: column;
            }
            .points, .matches-position{
                .text, .count, .position, .position-indicator{
                    font-weight: 700;
                }
            }
            .text, .count {
                font-weight: 500;
            }
            .table-data{
                height: 6.5rem;
            }
        }
    }
    .waf-accordion-head{
        @extend %w-100;
    }
}